<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								<h4>Tambah Aropt</h4>
							</CCol>
							
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<div class="form-group">
                                <label for=""><b>Kelompok Jenis Tanaman <span class="text-danger">*</span></b></label>
                                <v-select :options="list_tanaman" label="tk_nama" v-model="kelompok_selected" placeholder="Pilih Kelompok Jenis Tanaman"></v-select>
                            </div>
                            <div class="form-group">
                                <label for=""><b>Sub Kelompok Jenis Tanaman <span class="text-danger">*</span></b></label>
                                <v-select :options="kelompok_selected.tanaman_kategori_sub" label="tks_nama" v-model="subkelompok_selected" placeholder="Pilih Sub Kelompok Jenis Tanaman"></v-select>
                            </div>
							<div class="form-group">
                                <label for=""><b>Jenis Tanaman <span class="text-danger">*</span></b></label>
                                <v-select :filter="searchTanaman" :options="subkelompok_selected.tanaman" v-model="tanaman" placeholder="Pilih Jenis Tanamn">
                                	<template v-slot:option="value_tanaman">
										{{ value_tanaman.t_nama }} ({{ value_tanaman.t_nama_latin }} )
									</template>
									<template #selected-option="value_tanaman">
										{{ value_tanaman.t_nama }} ({{ value_tanaman.t_nama_latin }} )
									</template>
                                </v-select>
                            </div>
                            <div class="form-group">
                                <label for=""><b>Bentuk Benih <span class="text-danger">*</span></b></label>
                                <v-select :options="list_bentuk_benih" label="bb_nama" v-model="bentuk_benih" placeholder="Pilih Bentuk Benih"></v-select>	
                            </div>
                            <div class="form-group">
                                <label for=""><b>Negara Asal <span class="text-danger">*</span></b></label>
                                <v-select :filter="searchNegara" :options="list_negara" v-model="negara" placeholder="Pilih Negara Asal" >
									<template v-slot:option="value_list_negara">
										{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
									</template>
									<template #selected-option="value_list_negara">
										{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
									</template>
								</v-select>
                            </div>
                            

						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
							</div>
						</CCardFooter>
					</CForm>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	export default {
		name: "AddAropt",
		data() {
			return {
				tanaman: '',
				bentuk_benih: '',
				negara: '',
				list_tanaman: '',
				kelompok_selected: '',
				subkelompok_selected: '',
				list_bentuk_benih: [],
				list_negara: [],
			};
		},
		methods: {
			searchNegara(options, search) {
		      const fuse = new Fuse(options, {
		        keys: ["n_inisial", "n_nama"],
		        shouldSort: true
		      });
		      return search.length
		        ? fuse.search(search).map(({ item }) => item)
		        : fuse.list;
		    },
		    searchTanaman(options, search) {
		      const fuse = new Fuse(options, {
		        keys: ["t_nama", "t_nama_latin"],
		        shouldSort: true
		      });
		      return search.length
		        ? fuse.search(search).map(({ item }) => item)
		        : fuse.list;
		    },
			storeProcess: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				var FormData = require('form-data');
				var data = new FormData();
				data.append('tanaman_id', this.tanaman.id);
				data.append('bentuk_benih_id', this.bentuk_benih.id);
				data.append('negara_id', this.negara.id);
				var config = {
				  method: 'post',
				  url: this.apiLink+'api/crud/aropt',
				  headers: { 
					'Authorization': 'Bearer '+this.access_token, 
				  },
				  data : data
				};
				axios(config)
				.then(function (response) {
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Anda akan diarahkan ke halaman master aropt segera",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								self.$router.push({name: 'AllAropt'});
							}
						});
					}
				});
			},
		},
		created() {
            // Get Master Tanaman
            axios.get(this.apiLink + "api/master/tanaman", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_tanaman = response.data;
                var res_tanaman_data = res_tanaman.data;
                if (res_tanaman.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_tanaman.data.message
                    });
                }
                else{
                    this.list_tanaman = res_tanaman_data.master;
                    // console.log(res_tanaman_data.master);
                }
            });
            axios.get(this.apiLink + "api/master/bentuk_benih", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_bentuk_benih = response.data;
				var res_bentuk_benih_data = res_bentuk_benih.data;
				if (res_bentuk_benih.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_bentuk_benih.data.message
					});
				}
				else{
					this.list_bentuk_benih = res_bentuk_benih_data.master;
				}
			});
			// Get Master Negara
			axios.get(this.apiLink + "api/master/negara", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_negara = response.data;
				var res_negara_data = res_negara.data;
				if (res_negara.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_negara.data.message
					});
				}
				else{
					this.list_negara = res_negara_data.master;
				}
			});
        }
	};
</script>